import React from "react";

const About = () => {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Welcome to About Page!</h1>
        </div>
    );
}

export default About;