import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

const ContactFrom = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    /*
        emailjs.sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        form.current,
        "YOUR_PUBLIC_KEY"
        )
        */
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Email Sent Successfully.");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 sm:m-12">
      <div className="col-span-3 h-full flex flex-col items-start justify-center pb-8 mx-8">
        <h2 className="text-xl sm:text-4xl">
          Contact <span className="clr-primary">Us</span>
        </h2>
        <p className="text-sm text-start sm:text-lg">
          "Have a question or inquiry? Fill out the form, and we'll get back to you as soon as possible."
        </p>
      </div>
      <div className="col-span-3 my-5 sm:mx-14">
        <form ref={form} onSubmit={sendEmail}>
          <div className="sm:col-span-4 sm:mx-16 mx-10">
            <label
              htmlFor="name"
              className="text-start block text-sm font-bold leading-6 text-gray-900"
            >
              NAME
            </label>
            <div className="mb-2">
              <input
                id="name"
                name="name"
                type="text"
                required
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-4 sm:mx-16 mx-10">
            <label
              htmlFor="email"
              className="text-start block text-sm font-bold leading-6 text-gray-900"
            >
              EMAIL
            </label>
            <div className="mb-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-4 sm:mx-16 mx-10">
            <label
              htmlFor="phone"
              className="text-start block text-sm font-bold leading-6 text-gray-900"
            >
              PHONE
            </label>
            <div className="mb-2">
              <input
                type="tel"
                id="phone"
                name="phone"
                pattern="[+]{1}[0-9]{11,14}"
                required
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-4 sm:mx-16 mx-10">
            <label
              htmlFor="message"
              className="text-start block text-sm font-bold leading-6 text-gray-900"
            >
              MESSAGE
            </label>
            <div className="mb-2">
              <textarea
                id="message"
                name="message"
                rows="3"
                required
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div>
          <div className="mt-6 sm:mr-16 mr-10 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactFrom;
