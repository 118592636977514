import React from "react";
import ContactFrom from "../../components/ContactFrom/ContactForm";

const Contact = () => {
  return (
    <div className="w-full h-full flex items-center">
      <ContactFrom />
    </div>
  );
};

export default Contact;
