import React from "react";
import Map from "../Map/Map";
import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/instagram.png";
import twitter from "../../assets/icons/twitter.png";
import youtube from "../../assets/icons/youtube.png";

const Footer = () => {
  return (
    <div className="w-full h-72">
      <div className="w-full flex">
        <div className="flex flex-col w-5/12 text-start mx-10 py-16">
          <a href="/home" className="mb-2">
            <span className="text-xl title font-bold leading-6 text-gray-900">
              <span className="title-letter">G</span>lobeSpan{" "}
              <span className="title-letter">F</span>usion{" "}
              <span className="title-letter">S</span>ervices
            </span>
          </a>
          <p className="mb-5">Connecting World - Crafting Future.</p>
          <div>
            <p>
              Address:&nbsp;
              <span className="font-medium">
                24-1-1797, Saraswathi Nagar, Dargamitta, Nellore, Andhra
                Pradesh, India 524003
              </span>
            </p>

            <p>
              Telephone:&nbsp;
              <span className="font-medium">+91-9494245651</span>
            </p>
            <p>
              Email:&nbsp;
              <span className="font-medium">
                info@globespanfusionservices.com
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-start text-start w-2/12 mx-10 py-12">
          <h2 className="font-bold leading-10 mb-5">Our Services</h2>
          <ul className="list-none leading-8">
            <li>About</li>
            <li>Blog</li>
            <li>Careers</li>
            <li>FAQ's</li>
          </ul>
        </div>
        <div className="flex flex-col justify-start text-start w-2/12 mx-10 py-12">
          <h2 className="font-bold leading-10 mb-5">Company</h2>
          <ul className="list-none leading-8">
            <li>Terms of Use</li>
            <li>privacy</li>
            <li>Contact Us</li>
          </ul>
        </div>
        <div className="flex justify-center w-3/12 mx-10 my-12">
          <Map />
        </div>
      </div>
      <hr className="mx-10" />
      <div className="flex justify-between items-center mx-10 py-3">
        <p>© 2024 Globespan Fusion Services, Inc. All rights reserved.</p>
        <div className="flex items-center gap-5 text-xl">
          <a
            href="https://www.instagram.com/globespanfusionservices?igsh=d3M1YW13cHNkcGZi"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="Globespan Fusion Services Instagram" />
          </a>
          <a
            href="https://x.com/globespanfusion?s=21&t=kzbF3kFq677mpWIGKLo3Eg"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="Globespan Fusion Services Twitter" />
          </a>
          <a
            href="https://www.facebook.com/share/G6zZNBtMb5yL3p5w/?mibextid=WC7FNe"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="Globespan Fusion Services Facebook" />
          </a>
          <a
            href="https://youtube.com/@GlobespanFusionServices?si=1ZGlcVa1d7Qtnbs-"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="Globespan Fusion Services Youtube" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
