import React from "react";
import "./home.css";
import ContactFrom from "../../components/ContactFrom/ContactForm";

const Home = () => {
  return (
    <div className="bg-white w-full">
      <div className="relative isolate px-6 pt-8 lg:px-8">
        <div className="mx-auto max-w-3xl py-28 sm:py-32 lg:py-42">
          <div className="text-center">
            <h1 className="text-5xl font-bold tracking-tight text-gray-900">
              Welcome to
              <p className="block max-w-3xl sm:text-6xl">
                Globespan Fusion Services
              </p>
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Where innovation and excellence converge to redefine industry
              standards. We pride ourselves on our diverse array of services,
              crafted to cater to your unique needs. Delve into our offerings
              and uncover the possibilities we offer. Whether it's cutting-edge
              solutions or personalized assistance, we are dedicated to
              exceeding your expectations. Join us on this journey of innovation
              and let us demonstrate how we can seamlessly align with and
              surpass your requirements.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a>
              <a
                href="/"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ContactFrom />
      <hr className="mx-10" />
    </div>
  );
};

export default Home;
